import React from 'react';
import PropTypes from 'prop-types';
import CustomSlider from '../../Common/CustomSlider/CustomSlider';
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';
import './sliderBlock.scss';
import Fade from 'react-reveal/Fade';

const SliderBlock = ({ slider }) => {
  const backImage = !slider.backgroundImage.childImageSharp
    ? slider.backgroundImage
    : slider.backgroundImage.childImageSharp.fluid.src;

  const backImageStyle = {
    backgroundImage: 'url("' + backImage + '")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  const animationDelay = 500;

  return (
    <div className="bg-primary" style={backImageStyle}>
      <div className="container pt-9 pb-7">
        <div className="row">
          <div className="col">
            <Fade bottom delay={animationDelay}>
              <CustomSlider>
                {slider.slides.map((slide, i) => (
                  <section key={i} className="container-fluid text-light slide">
                    <div className="row align-items-md-center text-center text-lg-left">
                      <div className="col col-12 col-lg-6 px-sm-9 pl-lg-9 pr-lg-3">
                        <h3 className="h2 slide-title mb-4 mb-lg-5">{slide.title}</h3>
                        <p className="slide-description mb-4 mb-md-6">{slide.description}</p>
                      </div>
                      <div className="col col-12 col-lg-6 px-sm-9 pr-lg-9 pl-lg-0">
                        <div className="slide-image-wrap">
                          <div className="slide-image-container">
                            {slide && slide.imageContainer && (
                              <PreviewCompatibleImage
                                imageInfo={{
                                  image: slide.imageContainer.src,
                                  alt: slide.imageContainer.alt,
                                  className: 'slide-image',
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ))}
              </CustomSlider>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

SliderBlock.propTypes = {
  slider: PropTypes.object,
};

export default SliderBlock;
