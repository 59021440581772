import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import HeroBlock from '../components/Homepage/HeroBlock/HeroBlock';
import WhyYouShouldJoin from '../components/WhyYouShouldJoin/WhyYouShouldJoin';
import ThousandsOfSongs from '../components/Homepage/ThousandsOfSongs/ThousandsOfSongs';
import JoiningTG from '../components/Homepage/JoiningTG';
import SliderBlock from '../components/Homepage/SliderBlock/SliderBlock';
import JoinTGCommunity from '../components/Homepage/JoinTGCommunity/JoinTGCommunity';
import LearningGuitar from '../components/LearningGuitar';
import JoinForFree from '../components/Homepage/JoinForFree';

export const HomePageTemplate = ({ frontmatter }) => (
  <React.Fragment>
    <HeroBlock
      smTitle={frontmatter.heroBlock.smTitle}
      bgTitle={frontmatter.heroBlock.bgTitle}
      description={frontmatter.heroBlock.description}
      backVideo={frontmatter.heroBlock.backVideo}
      backImage={frontmatter.heroBlock.backImageContainer}
      backSmallImage={frontmatter.heroBlock.backSmallImageContainer}
      buttonLink={frontmatter.heroBlock.buttonLink}
    />
    <WhyYouShouldJoin
      title={frontmatter.whyYouShouldJoin.title}
      headline={frontmatter.whyYouShouldJoin}
      accessThousands={frontmatter.accessThousands}
      personalReviews={frontmatter.personalReviews}
      theBestGuidance={frontmatter.theBestGuidance}
    />
    <JoiningTG
      title={frontmatter.joiningTG.title}
      benefits={frontmatter.joiningTG.benefits}
    />
    <div className="overflow-hidden">
      <SliderBlock slider={frontmatter.sliderBlock} />
      <JoinTGCommunity
        title={frontmatter.joinTGCommunity.title}
        description={frontmatter.joinTGCommunity.description}
        buttonLink={frontmatter.joinTGCommunity.buttonLink}
        backgroundImage={frontmatter.joinTGCommunity.backgroundImage}
      />
    </div>
    <LearningGuitar
      title={frontmatter.learningGuitar.title}
      imageContainer={frontmatter.learningGuitar.imageContainer}
      lists={frontmatter.learningGuitar.lists}
    />
    <JoinForFree
      title={frontmatter.joinForFree.title}
      description={frontmatter.joinForFree.description}
      buttonLink={frontmatter.joinForFree.buttonLink}
      backgroundImage={frontmatter.joinForFree.backgroundImage}
    />
  </React.Fragment>
);

HomePageTemplate.propTypes = {
  frontmatter: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout title="Home">
      <HomePageTemplate frontmatter={frontmatter} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        accessThousands {
          buttonLink{
            label,
            path
          }
          slider {
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          description
          title
        }
        heroBlock {
          buttonLink{
              label,
              path
          }
          description
          smTitle
          bgTitle
          backVideo
          backImageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          backSmallImageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 650) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        joinForFree {
          buttonLink{
              label,
              path
          }
          description
          title
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        joinTGCommunity {
          buttonLink{
              label,
              path
          }
          description
          title
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        joiningTG {
          title
          benefits {
            buttonLink{
                label,
                path
            }
            description
            title
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
        }
        learningGuitar {
          title
          lists {
            title
            items 
          }
          imageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 650) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        personalReviews {
          buttonLink{
              label,
              path
          }
          description
          title
          videoURL
        }
        sliderBlock {
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slides {
            description
            title
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
        }
        theBestGuidance {
          buttonLink{
              label,
              path
          }
          description
          title
          videoURL
        }
        whyYouShouldJoin {
          buttonLink{
              label,
              path
          }
          description
          subTitle
          title
          videoURL
        }
      }
    }
  }
`;
