import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from "../../Common/PreviewCompatibleImage";
import './heroBlock.scss';
import MemoVideoPlayer from '../../Common/VideoPlayer/VideoPlayer';

const HeroBlock = ({ smTitle, bgTitle, description, buttonLink, backVideo, backSmallImage, backImage }) =>{

  const backImageUrl = !backImage.src.childImageSharp
    ? backImage.src
    : backImage.src.childImageSharp.fluid.src;

  const backImageStyle = {
    backgroundImage: 'url("' + backImageUrl + '")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    <section className="position-relative overflow-hidden" style={backImageStyle}>

      <div className="d-none d-md-block">
        <MemoVideoPlayer
          video={{
            url: backVideo,
            platform: 'vimeo'
          }}
          isBackground={true}
          autoplay={true}
          title={false}
          muted={true}
          loop={true}
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col hero-section-content-wrap d-flex pt-5 pb-8 pt-lg-9 pb-lg-12 min-vh-xl-50">
            <div className="text-light align-self-center hero-section-content">
              <h1 className="hero-title">
                <span className="hero-title-small font-weight-bold">{smTitle}</span> <br/>
                {bgTitle}
              </h1>
              <hr />
              <p className="w-100 w-md-75">{description}</p>
              <a href={buttonLink.path} className="mt-4 btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">{buttonLink.label}</a>
            </div>
            <div className="hero-image-block d-none d-md-block">
              {backSmallImage && backSmallImage.src && (<PreviewCompatibleImage
                imageInfo={{
                  image: backSmallImage.src,
                  alt: backSmallImage.alt,
                }}
              />)}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

HeroBlock.propTypes = {
  smTitle: PropTypes.string,
  bgTitle: PropTypes.string,
  description: PropTypes.string,
  backVideo: PropTypes.string,
  buttonLink: PropTypes.object,
  backSmallImage: PropTypes.object,
  backImage: PropTypes.object
};

export default HeroBlock;
