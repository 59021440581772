import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../Common/PreviewCompatibleImage';
import Fade from 'react-reveal/Fade';

const animationDelay = 500;
const distance = '50%';

const JoiningTG = ({
  title,
  benefits
}) => (
  <section className={'joining-tg'}>
    <div className="container pt-3 pt-md-5 pb-7">
      <div className="row">
        <div className="col">
          <Fade left delay={animationDelay}>
            <h2 className="text-primary text-center py-4 mb-3 mb-md-5 bg-primary-200">{title}</h2>
          </Fade>
        </div>
      </div>
      <div className="row">
        {
          benefits.map((item, i) => (
            <Fade key={i}  right delay={((i + 1) * 200)} distance={distance}>
              <div className="col col-12 col-md-6 col-lg-4 mb-3 mb-md-5">
                <section className="position-relative h-100 card-benefit px-3 py-6 py-xl-7">
                  <div>
                    {item && item.imageContainer && (<PreviewCompatibleImage
                      imageInfo={{
                        className: "benefit-img mb-4",
                        image: item.imageContainer.src,
                        alt: item.imageContainer.alt,
                      }}
                    />)}
                    <h3 className="card-title text-primary mb-4">{item.title}</h3>
                  </div>
                  <p className="mb-4 mt-mb-5">{item.description}</p>
{/*
                  {item.buttonLink && <a href={item.buttonLink.path} className="flex-shrink-0 mt-auto btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">{item.buttonLink.label}</a>}
*/}
                </section>
              </div>
            </Fade>
          ))
        }
      </div>
    </div>
  </section>
);

JoiningTG.propTypes = {
  title: PropTypes.string,
  benefits: PropTypes.array,
};

export default JoiningTG
