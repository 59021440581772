import React from 'react';
import PropTypes from 'prop-types';
import './whyYouShouldJoin.scss';
import Headline from './components/Headline';
import AccessThousands from './components/AccessThousands';
import PersonalReviews from './components/PersonalReviews';
import TheBestGuidance from './components/TheBestGuidance';
import Fade from 'react-reveal/Fade';

const distance = '30%';

const WhyYouShouldJoin = ({
  title,
  description,
  headline,
  accessThousands,
  personalReviews,
  theBestGuidance
}) => (
  <section className='container py-7'>
    <div className='row'>
      <div className='col'>
        <h2 className='text-primary text-center mb-5'> {title} </h2>
        {description && <p className="text-center mx-0 mx-lg-9 mx-xl-10 mb-6">{description}</p>}
      </div>
    </div>
    {
      headline && (
        <Fade right distance={distance}>
          <Headline
            title={headline.subTitle}
            videoURL={headline.videoURL}
            description={headline.description}
            buttonLink={headline.buttonLink}
          />
        </Fade>
      )
    }
    <hr className="divider d-md-none row" />
    <Fade left distance={distance}>
      <AccessThousands
        title={accessThousands.title}
        slider={accessThousands.slider}
        description={accessThousands.description}
        buttonLink={accessThousands.buttonLink}
      />
    </Fade>
    <hr className="divider d-md-none" />
    <Fade right distance={distance}>
      <PersonalReviews
        title={personalReviews.title}
        videoURL={personalReviews.videoURL}
        description={personalReviews.description}
        buttonLink={personalReviews.buttonLink}
      />
    </Fade>
    <hr className="divider d-md-none" />
    <Fade left distance={distance}>
      <TheBestGuidance
        title={theBestGuidance.title}
        videoURL={theBestGuidance.videoURL}
        description={theBestGuidance.description}
        buttonLink={theBestGuidance.buttonLink}
      />
    </Fade>
  </section>
);

WhyYouShouldJoin.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  headline: PropTypes.object,
  accessThousands: PropTypes.object,
  personalReviews: PropTypes.object,
  theBestGuidance: PropTypes.object
};

export default WhyYouShouldJoin
