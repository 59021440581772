import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

const animationDelay = 300;
const distance = '50%';


const JoinForFree = ({ title, description, buttonLink, backgroundImage }) => {
  const backImage = !backgroundImage.childImageSharp
    ? backgroundImage
    : backgroundImage.childImageSharp.fluid.src;

  const backImageStyle = {
    backgroundImage: 'url("' + backImage + '")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  };

  return (
    <section style={backImageStyle} className="bg-primary text-center text-white mask-dark"></section>
  );
};


JoinForFree.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLink: PropTypes.object,
  backgroundImage: PropTypes.object,
};

export default JoinForFree;
